// Webpack Imports
import 'bootstrap';


( function ( $ ) {
	'use strict';

	// Focus Search if Searchform is empty
	$( '.search-form' ).on( 'submit', function ( e ) {
		var search = $( '#s' );
		if ( search.val().length < 1 ) {
			e.preventDefault();
			search.focus();
		}
	} );

}( jQuery ) );





var global = {
	init: function() {
		global.browserDetect();
		global.detectTouch();
		global.nav();
		global.statusIndication();
		global.slideIn();
		global.search();

		var slideCheck = $('.slideIn').length;
		if ( slideCheck > 0 ) {
			global.slideIn();
		}

		var slideStaggerCheck = $('.slideStagger').length;
		if ( slideStaggerCheck > 0 ) {
			global.slideInStagger();
		}

		var videoCheck = $('.lightboxVideo').length;
		if ( videoCheck > 0 ) {
			global.video();
		}

		var iCalExportCheck = $('.tribe-events-ical[title="Download .ics file"]').length;
		if ( iCalExportCheck > 0 ) {
			global.iCalRename();
		}

	},

	controller: new ScrollMagic.Controller({}),

	browserDetect: function(){
		var ua = window.navigator.userAgent;
		var msie = ua.indexOf('MSIE ');
		var trident = ua.indexOf('Trident/');
		var edge = ua.indexOf('Edge/');
		var ff = ua.indexOf("Firefox");
		var safari = ua.indexOf('Safari');
		var chrome = ua.indexOf('Chrome')

		if (msie > 0) {
			$('html').addClass('ie11');
		} else if (trident > 0) {
			$('html').addClass('ie11');
		} else if (edge > 0) {
			$('html').addClass('edge');
		} else if (ff > -1){
			$('html').addClass('ff');
		} else if (safari > 0 && chrome < 0) {
			$('html').addClass('safari');
		} else if (chrome > 0) {
			$('html').addClass('chrome');
		}
	},

	detectTouch: function() {
		if ("ontouchstart" in document.documentElement) {
			$('html').addClass('touch');
		}
	},

	search: function() {
		$( '.search-form' ).on( 'submit', function ( e ) {
			var empt = document.forms["searchForm"]["s"].value;

			if (empt == "") {
				alert("Please input a value to search");
				return false;
			}
		} );


		$('.searchButton').on('click', function(e) {
			e.preventDefault();

			$(this).addClass('active');

			var searchButtonTL = gsap.timeline({});
			searchButtonTL.to('.searchButton', .5, { autoAlpha: 0, position: 'absolute' });

			$('.search-form').addClass('active');

			$('.search-form input').focus();
		});

		$('.search-form input').on('focusout', function() {
			$('.search-form').removeClass('active');
			$('.searchButton').removeClass('active');
			gsap.to('.searchButton', .5, { autoAlpha: 1, position: 'relative' });
		});
	},

	statusIndication: function() {
		$('[aria-current="page"]').closest('#menu-main-menu > li').addClass('active');
	},

	nav: function() {
		if ($('html').hasClass('touch')) {
			// $('.nav-item').on('click', function() {
			// 	$('.nav-item .dropdown-menu').dropdown('hide');
			// });

			$('.navbar-toggler').on('click', function() {

				if (!$(this).hasClass('active')) {
					// $('.nav-item.active .dropdown-menu').dropdown('show');
					$(this).addClass('active');
					$('body').css('overflow', 'hidden');
				} else {
					// $('.nav-item .dropdown-menu').dropdown('hide');
					$(this).removeClass('active');
					$('body').css('overflow', 'auto');
				}
			});
		} else {
			$('.menu-item').on('mouseenter', function() {
				$('.dropdown').removeClass('hover');
				$(this).addClass('hover');
			});

			$('.dropdown-menu').on('mouseleave', function() {
				$('.dropdown').removeClass('hover');
			});
		}

	},

	slideIn: function() {
		/* General slide in trigger */

		$('.slideIn').each(function() {
			var el = $(this);

			var scene = new ScrollMagic.Scene({
				triggerHook: .75,
				triggerElement: el,
				offset: 0,
				reverse: false
			})
			.setTween(el, 0.5, { opacity: 1, x: 0, y: 0 })
			.addTo(global.controller);
		});
	},

	slideInStagger: function() {
		var el = $('.slideStagger');

		var scene = new ScrollMagic.Scene({
			triggerHook: 'onEnter',
			triggerElement: el,
			offset: 200,
			reverse: false
		})
		.setTween(el, 0.5, { stagger: .15, opacity: 1, x: 0, y: 0 })
		.addTo(global.controller);
	},

	video: function() {
		$('.lightboxVideo').simpleLightbox();
	},

	iCalRename: function() {
		$('.tribe-events-ical[title="Download .ics file"]').text('+ iCal/Outlook Export');
	}
}
global.init();


var accordion = {
	init: function() {
		var check = $('.accordion').length;

		if (check > 0) {
			accordion.click();
		}
	},

	click: function() {
		$('.card').on('click', function() {
			if (!$(this).hasClass('active')) {
				$('.card').removeClass('active');
				$(this).addClass('active');
				$(this).find('.collapse').attr('aria-expanded', 'true')
			} else {
				$('.card').removeClass('active');
				$('.collapse').attr('aria-expanded', 'false')
			}
		});
	}
}
accordion.init();



var seniorManagement = {
	init: function() {
		var check = $('.seniorManagement').length;

		if (check > 0) {
			seniorManagement.onHover();
		}
	},

	onHover: function() {
		$('.bioBlockImage').on('click', function() {
			$('.bioBlock').removeClass('active');
			$(this).closest('.bioBlock').addClass('active');
			$('.pageOverlay').addClass('active');

			gsap.to(window, {duration: 1, scrollTo:{y:'.bioBlock.active', offsetY:50}});

			if ($(this).closest('.bioBlock').is(':last-of-type') && !$('html').hasClass('touch')) {
				var h = $(this).closest('.bioBlock').find('.bioContent').height();

				$(this).closest('.bioBlock').css('padding-bottom', h)
			}
		});

		$('.closeButton').on('click', function() {
			$('.bioBlock').removeClass('active');
			$('.pageOverlay').removeClass('active');
			$('.bioBlock:last-of-type').css('padding-bottom', 0);
		});
	}
}
seniorManagement.init();


var participantsMap = {
	init: function() {
		var check = $('.participantsList').length;

		if (check > 0) {
			participantsMap.mapMove();
			participantsMap.map();
		}
	},

	mapMove: function() {
		$('.participantMap').remove().clone().appendTo('.participantMapWrap');
		$('.participantMap').css('display', 'block');
	},

	map: function() {
		var formatText = function(city) {
			var city = city.toLowerCase();
			var city = city.split(' ').join('-');

			return city;
		}

		$('.participantsList li').on('click', function() {
			var city = $(this).attr('data-city');

			$('.participantsList li').removeClass('active');
			$(this).addClass('active');

			$('.participantMap g').removeClass('active');
			$('.participantMap').find("#"+formatText(city)).addClass('active');

			$('[data-profile]').removeClass('active');
			$('[data-profile="'+city+'"]').addClass('active');

			gsap.to(window, {duration: 1, scrollTo:'[data-profile="'+city+'"]'});
			gsap.to('[data-profile="'+city+'"]', 1, { autoAlpha: 1 });
		});

		$('[data-city]').on('mouseenter', function() {
			var city = $(this).attr('data-city');
			var city = formatText(city);

			$('.participantMap').find("#"+city).addClass('hover');
		});

		$('[data-city]').on('mouseleave', function() {
			$('.participantMap g').removeClass('hover');
		});
	}
}
participantsMap.init();


var testimonial = {
	init: function() {
		var check = $('.testimonial').length;

		if (check > 0) {
			testimonial.click();
		}
	},

	click: function() {
		$('.testimonialButton').on('click', function() {
			$(this).addClass('active');
			var t = $(this).data('testimonial');
			$('.testimonialContent[data-testimonial-content='+t+']').addClass('active');

			gsap.to(window, {duration: 1, scrollTo:{y:'.testimonialContent.active', offsetY:100}});
			$('.pageOverlay').addClass('active');

		});

		$('.testimonialContent__inner .closeButton').on('click', function() {
			$('.testimonialContent').removeClass('active');
			$('.pageOverlay').removeClass('active');
		});
	}
}
testimonial.init();
